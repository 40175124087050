
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexME4Fi816ZoO997wVz_45cG9X6_Fi8uqzuLzBQQ5ooUYMwMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/index.vue?macro=true";
import { default as logoutkuTqUgBUKn4c_xyQK93aIibu6hM7kjS4CPxTI4EQhBIMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/logout.vue?macro=true";
import { default as Login19oU_U_45eQZrlWH8KkJnnpN5CHdFPGYl2_we1kl2p0C8Meta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Login.vue?macro=true";
import { default as Users8T9Nk96xGiscIXphPJeAQirfXnL7eBTp9MVEqkvhRqIMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/tests/Users.vue?macro=true";
import { default as ReportsNQEJsU63V4ZQUHyT2v2mAtl7iBJuHCf8an_45pmfwJLWYMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/admin/Reports.vue?macro=true";
import { default as RegisterSkKc69uKRFT5ai23BTOwEgHuCm1iCiNhNP0j9RIQjtEMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Register.vue?macro=true";
import { default as MyProfileSQMrtpFuQ6j9y_45Gt0au4ERqyLcZe7uc8BAEnbCkD1DoMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyProfile.vue?macro=true";
import { default as MyPurchasesi4tmGnLrBXqWYw0EhVADPWIMvPyWRLTxq5Q6giZbCpMMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyPurchases.vue?macro=true";
import { default as MyCreditCardsmtD_HdxxFMsQpZj7dZF_45ZjKEE9gb9ymCUGZDX2H7yl8Meta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyCreditCards.vue?macro=true";
import { default as Reset_45Passwordi43Mm2SrHRn_tL6pu_457vViFK4_45iGZeMBj303XH6v_458IMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Reset-Password.vue?macro=true";
import { default as delete_45accountSZG9tOGbHij_45_456REqNz1U0oDlZ_45JPEATa2S3LFleDagMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/help/delete-account.vue?macro=true";
import { default as CheckoutFBIFllwfzqOhKbtdUI99y9LXDuEscJ8QWC29oAKai_4Meta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/Checkout.vue?macro=true";
import { default as _91uuid_93AHMB5gw_45chwFlam6OQHiN_fBvttxS0G33bzQv1ckNesMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/reset-password/[uuid].vue?macro=true";
import { default as checkoutMixinozcjHG3acsByafBVRQrS4JwuzE9AKAXXhRsSFnTlyJMMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/checkoutMixin.ts?macro=true";
import { default as AddCreditCardqt_2QHLXwzvGPR8PJJgf8pCcmF3PvySOsPu2wCE7o7IMeta } from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/AddCreditCard.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/index.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logoutkuTqUgBUKn4c_xyQK93aIibu6hM7kjS4CPxTI4EQhBIMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/logout.vue")
  },
  {
    name: "auth-Login",
    path: "/auth/Login",
    meta: Login19oU_U_45eQZrlWH8KkJnnpN5CHdFPGYl2_we1kl2p0C8Meta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Login.vue")
  },
  {
    name: "tests-Users",
    path: "/tests/Users",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/tests/Users.vue")
  },
  {
    name: "admin-Reports",
    path: "/admin/Reports",
    meta: ReportsNQEJsU63V4ZQUHyT2v2mAtl7iBJuHCf8an_45pmfwJLWYMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/admin/Reports.vue")
  },
  {
    name: "auth-Register",
    path: "/auth/Register",
    meta: RegisterSkKc69uKRFT5ai23BTOwEgHuCm1iCiNhNP0j9RIQjtEMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Register.vue")
  },
  {
    name: "user-MyProfile",
    path: "/user/MyProfile",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyProfile.vue")
  },
  {
    name: "user-MyPurchases",
    path: "/user/MyPurchases",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyPurchases.vue")
  },
  {
    name: "user-MyCreditCards",
    path: "/user/MyCreditCards",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/MyCreditCards.vue")
  },
  {
    name: "auth-Reset-Password",
    path: "/auth/Reset-Password",
    meta: Reset_45Passwordi43Mm2SrHRn_tL6pu_457vViFK4_45iGZeMBj303XH6v_458IMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/Reset-Password.vue")
  },
  {
    name: "help-delete-account",
    path: "/help/delete-account",
    meta: delete_45accountSZG9tOGbHij_45_456REqNz1U0oDlZ_45JPEATa2S3LFleDagMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/help/delete-account.vue")
  },
  {
    name: "apps-ecommerce-Checkout",
    path: "/apps/ecommerce/Checkout",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/Checkout.vue")
  },
  {
    name: "auth-reset-password-uuid",
    path: "/auth/reset-password/:uuid()",
    meta: _91uuid_93AHMB5gw_45chwFlam6OQHiN_fBvttxS0G33bzQv1ckNesMeta || {},
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/auth/reset-password/[uuid].vue")
  },
  {
    name: "apps-ecommerce-checkoutMixin",
    path: "/apps/ecommerce/checkoutMixin",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/apps/ecommerce/checkoutMixin.ts")
  },
  {
    name: "user-credit-card-AddCreditCard",
    path: "/user/credit-card/AddCreditCard",
    component: () => import("/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/pages/user/credit-card/AddCreditCard.vue")
  }
]