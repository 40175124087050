export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"property":"og:title","content":"Snapei"},{"property":"og:description","content":"¡No más filas, compra con Snapei!"},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://snapei.triduum.us"},{"property":"og:locale","content":"es_CO"},{"property":"og:image","content":"https://triduum.us/images/snapei-logo-mini.png"},{"property":"og:image:width","content":"40"},{"property":"og:image:height","content":"40"},{"property":"fb:app_id","content":"383049041051650"},{"name":"twitter:site","content":"@TriduumUs"},{"name":"twitter:image","content":"https://triduum.us/images/snapei-logo-mini.png"},{"name":"theme-color","content":"rgb(237,243,252)"},{"name":"color-scheme","content":"light"},{"name":"application-name","content":"Snapei"},{"name":"apple-itunes-app","content":"app-id=6737234551"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"icon","type":"image/png","href":"/favicon.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"}],"style":[],"script":[{"src":"https://sdk.mercadopago.com/js/v2","async":true},{"src":"https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js","async":true}],"noscript":[],"charset":"utf-8","title":"Snapei"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = false

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = true

export const spaLoadingTemplateOutside = false

export const purgeCachedData = true