import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_amcCQ2WESqorJPIcCBfr0SoJux9Ycqp__WbI4xOJhLc from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/nuxt-vue3-google-signin/dist/runtime/plugin.mjs";
import vue_qrcode_reader_psywnIq07WSRpcAlIvxLFH7g3C1WpMcviOApI1j3ZnE from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vue-qrcode-reader.js";
import plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4 from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/error-handler.ts";
import mercadopago_1LRZlFYc7_fWfhrBvSLR_uFmGiJVAZiSwC7XKjw4kkM from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/mercadopago.js";
import nuxtReloadAttempt_a_WvDXifSfoclcqeNvGSgKddxUlZTmI_h_YRpOWghfM from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/nuxtReloadAttempt.js";
import pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/pinia.ts";
import safeArea_Pc_g1SP26LRU2uzUBoxLJBR83sE0_Nk0rQwjPsKbpoo from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/safeArea.ts";
import vee_validate__2bfAHR5jEWrgXdXOPIb_c4VonIeemKRcUwX_VkEWhA from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vee-validate.js";
import vue_qrcode_wqy_32CeUW9cH4kYEVsxFKtI0AKGr_RGQoycsatUr1U from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vue-qrcode.ts";
import vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds from "/Users/jorgegonzalez/Proyectos/Snapei/snapei-front/plugins/vuetify.ts";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3__Mow596nDQwJvvpPi_lHWeKcoEwbUskdc2VRygziuCY,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_amcCQ2WESqorJPIcCBfr0SoJux9Ycqp__WbI4xOJhLc,
  vue_qrcode_reader_psywnIq07WSRpcAlIvxLFH7g3C1WpMcviOApI1j3ZnE,
  plugin_VW0S5L6gti_L2lJP5Fh8KfD6_3GK52P9spdjsc0v3y4,
  error_handler_DNMY46R1VrmPTze2vUP_MqY_D_J1FfFG_ohDUaaTsSU,
  mercadopago_1LRZlFYc7_fWfhrBvSLR_uFmGiJVAZiSwC7XKjw4kkM,
  nuxtReloadAttempt_a_WvDXifSfoclcqeNvGSgKddxUlZTmI_h_YRpOWghfM,
  pinia_Ab1D_QMMf_egrzVdVYpZ5xs6QSwaKU60pkFvRWVq_9Y,
  safeArea_Pc_g1SP26LRU2uzUBoxLJBR83sE0_Nk0rQwjPsKbpoo,
  vee_validate__2bfAHR5jEWrgXdXOPIb_c4VonIeemKRcUwX_VkEWhA,
  vue_qrcode_wqy_32CeUW9cH4kYEVsxFKtI0AKGr_RGQoycsatUr1U,
  vuetify_hjFy4UiBVKu2U8_BW9ggkFzfvErKr3wFgTHpa6TF5Ds
]